'use es6';

(function () {
  var COOKIE_NAME = "hs-ard";
  var COOKIE_DOMAIN = window.location.hostname.indexOf('hubspot.com') > 0 ? ".hubspot.com" : ".hs-sitesqa.com";
  var COOKIE_DURATION = 8640000;
  /*\
  |*|
  |*|	:: cookies.js ::
  |*|
  |*|	A complete cookies reader/writer framework with full unicode support.
  |*|
  |*|	Revision #3 - July 13th, 2017
  |*|
  |*|	https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
  |*|	https://developer.mozilla.org/User:fusionchess
  |*|	https://github.com/madmurphy/cookies.js
  |*|
  |*|	This framework is released under the GNU Public License, version 3 or later.
  |*|	http://www.gnu.org/licenses/gpl-3.0-standalone.html
  |*|
  |*|	Syntaxes:
  |*|
  |*|	* docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
  |*|	* docCookies.getItem(name)
  |*|
  \*/

  var docCookies = {
    getItem: function getItem(sKey) {
      if (!sKey) {
        return null;
      }

      return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    },
    setItem: function setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
      if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
        return false;
      }

      var sExpires = "";

      if (vEnd) {
        switch (vEnd.constructor) {
          case Date:
            sExpires = "; expires=" + vEnd.toUTCString();
            break;
        }
      }

      document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
      return true;
    }
  };

  function isAmbassadorReferralPage() {
    return window.location.search.indexOf("mbsy=") > -1;
  }

  if (null === docCookies.getItem(COOKIE_NAME) && isAmbassadorReferralPage()) {
    docCookies.setItem(COOKIE_NAME, new Date().getTime(), new Date(COOKIE_DURATION * 1e3 + Date.now()), '/', COOKIE_DOMAIN, true);
  }
})();